import { env } from "utility/config";
import { toast } from "react-toastify";
import FileUploader from "views/components/DropZone";
import CreatableSelect from "react-select/creatable";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { supportGetDynamicFields, supportLogin, supportStoreTickets } from "utility/helper/endpoints";
import { Button, Modal, FormFeedback, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input } from "reactstrap";

import CustomInput from "views/components/@custom/Input";
import Editor from "./Editor";
import LoaderButton from "./LoaderButton";
import { HtmlStringConvert } from "./HtmlConvertTo";

import "./editor.scss";
import axios from "axios";
import { encryptPassword } from "utility/securePass/encryptPassword";

// env variables for support form
const { SUPPORT_API_BASEURL, SUPPORT_ORGANIZATION_ID, SUPPORT_TEAM_ID, SUPPORT_SUB_TEAM_ID } = env;

// endpoints
const store_tickets = `${
  SUPPORT_API_BASEURL + supportStoreTickets
}?forData=true&organization_id=${SUPPORT_ORGANIZATION_ID}`;

const get_dynamic_fields = `${SUPPORT_API_BASEURL}${supportGetDynamicFields}/${SUPPORT_TEAM_ID}?forData=true&organization_id=${SUPPORT_ORGANIZATION_ID}`;

const login = `${SUPPORT_API_BASEURL + supportLogin}?forData=true`;

// initial Object
const supportObj = {
  attachment: [],
  dynamic_fields: [],
  subject: "",
};
let nhcc_token = "";

function SupportForm({ isLogin, visible, setVisible, minWidth = 380, maxWidth = 450 }) {
  const context = useIntl();
  const [Data, setData] = useState(supportObj);
  const [postComment, setPostComment] = useState(false);
  const [ticketDone, setTicketDone] = useState(false);
  const [validation, setValidation] = useState(false);
  const [region, setRegion] = useState([]);
  const [Org, setOrg] = useState();
  const [value, setValue] = useState("");

  const fileRef = useRef();

  const [dynamicFields, setDynamicFields] = useState([]);

  useEffect(() => {
    getToken();
  }, []);
  // handle change input value form field
  const handleChange = ({ key, value }) => {
    setData({ ...Data, [key]: value });
    // setDynamicValue(value)
  };

  const onDynamicField = ({ key, value }) => {
    setData({
      ...Data,
      dynamic_fields: {
        ...Data.dynamic_fields,
        [key]: value,
      },
    });
  };

  const getToken = async () => {
    console.log("get token");
    const u = env.SUPPORT_EMAIL;
    const p = env.SUPPORT_PASSWORD;

    let data = {
      email: u,
      password: encryptPassword(p, env.SUPPORT_PASS_ENCRYPT_KEY),
      // dataTeam: "True",
      fromDevice: "web",
      fcmToken:
        "f-yWAXEmsyaQD8jMacoKwU:APA91bEIeBNH_7bwOH0S_bMZJwgh1ZfETzqEZDS59nTgfwp5sh508ovWKXQGVQTYhnsZutXCYT1uOxIwK2Qqxk1YseW_SL5GNJtDg9jRxxW4E3prmeu4PB1XvPP4HLZVnaoXyg6yEY49",
    };
    const requestOptions = {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "Clear-Site-Data": `${"cache"},${"cookies"},${"storage"},${"executionContexts"}`,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await axios.post(login, data, requestOptions);
      console.log("nhcc token", response, response?.data?.accessToken);

      nhcc_token = response?.data?.accessToken;

      delete requestOptions.body;
      try {
        const { data: dynamic_fields_response } = await axios.get(get_dynamic_fields, {
          ...requestOptions,
          headers: {
            ...requestOptions.headers,
            Authorization: `Bearer ${nhcc_token}`,
          },
        });
        setDynamicFields(dynamic_fields_response?.data);

        let DF = {};
        if (dynamic_fields_response.data?.length) {
          dynamic_fields_response.data.forEach((df) => {
            DF = {
              ...DF,
              [df._id]: "",
            };
          });
        }
        setData({
          ...Data,
          dynamic_fields: DF,
        });
      } catch (error) {}
    } catch (error) {
      console.log(error);
    }
  };

  // Submit data to server side
  const handleSubmit = (data) => {
    let DynamicFields =
      Object.entries(Data?.dynamic_fields || {}).map(([key, feildValue]) => {
        let newValue = feildValue;
        if (newValue?.label) {
          newValue = newValue?.value;
        }
        return {
          [key]: newValue,
        };
      }) || [];
    const postData = {
      ...data,
      priority_id: "medium",
      status_id: "opened",
      subject: data.subject,
      content: value,
      team_ids: [SUPPORT_TEAM_ID],
      // sub_team: [SUPPORT_SUB_TEAM_ID],
      attachment: Data.attachment.map((img) => img?.Location).filter((i) => i),
      dynamic_fields: DynamicFields,
      ticketCreateKey: env.SUPPORT_TICKET_CREATE_KEY,
    };

    const selectedFields = postData.dynamic_fields;
    let isValid = true;

    dynamicFields.forEach((df) => {
      if (df.requiredField) {
        isValid = selectedFields.reduce((result, cItem) => {
          if (df._id in cItem) {
            result = result && !!cItem[df._id];
          }
          return result;
        }, isValid);
      }
    });

    if (postData.subject?.trim() === "" || !isValid) {
      setValidation(true);
    } else {
      setValidation(false);
      setTicketDone(true);
      fetch(store_tickets, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${nhcc_token}`,
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log("support resp", response);
          if (response?.data?._id) {
            toast.success("Thank you for contacting us, we will get back to you!");
            setPostComment(true);
            setValue("");
            handleTogle();
            setTicketDone(false);
            if (fileRef?.current?.reset) {
              fileRef.current.reset();
            }
          } else {
            const error =
              response?.message?.errors === "string"
                ? response.message.errors
                : response.data.value === "string"
                ? response.data.value
                : "Something went wrong!";
            toast.error(error);
            console.log("support error", error);
            setTicketDone(false);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong!");
          setTicketDone(false);
        });
    }
  };

  const isDynamicFieldValid = (df) => {
    let isValid = true;
    let DynamicFields =
      Object.entries(Data?.dynamic_fields || {}).map(([key, feildValue]) => {
        return {
          [key]: feildValue,
        };
      }) || [];

    if (df.requiredField) {
      isValid = DynamicFields.reduce((result, cItem) => {
        if (df._id in cItem) {
          result = result && !!cItem[df._id];
        }
        return result;
      }, isValid);
    }

    return isValid;
  };

  const handleTogle = () => {
    setVisible((v) => !v);
    setPostComment(true);

    let DF = {};
    if (dynamicFields?.length) {
      dynamicFields.map((df) => {
        DF = {
          ...DF,
          [df._id]: "",
        };
      });
    }

    setData({ ...supportObj, dynamic_fields: DF });

    setValue("");

    setTicketDone(false);
  };

  const FormattedPlaceholder = ({ plcValue }) => {
    return <FormattedMessage id={plcValue} defaultMessage={plcValue} />;
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => handleTogle()}
      style={{
        ...(isLogin ? { position: "absolute", bottom: "0", left: "10%" } : { display: "flex", alignSelf: "center" }),
        maxWidth,
        minWidth,
      }}
      className="ticket-modal"
    >
      <ModalHeader toggle={() => handleTogle()}>
        <FormattedMessage id="Support Form" defaultMessage="Support Form" />
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="subject">
            <FormattedMessage id="Subject" defaultMessage="Subject" />
            <strong className="text-danger">*</strong>
          </Label>
          <CustomInput
            id="subject"
            type="subject"
            name="subject"
            value={Data.subject}
            handleChange={handleChange}
            placeholder={"Subject"}
            invalid={!!(Data.subject?.trim() === "" && validation)}
          />
          <FormFeedback className="ml-1" invalid={Data.subject?.trim() === "" && validation ? "true" : "false"}>
            This field is required!
          </FormFeedback>
        </FormGroup>
        {dynamicFields.map((item, index) => {
          switch (item.type) {
            case "select":
              return (
                <Fragment key={index}>
                  <FormGroup className="selector">
                    <label>
                      <FormattedMessage id={item.name} defaultMessage={item.name} />
                      {item.requiredField && <strong className="text-danger">*</strong>}
                    </label>
                    <CreatableSelect
                      className="React create-field-slect-1"
                      classNamePrefix="select"
                      isValidNewOption={() => false}
                      placeholder={<FormattedPlaceholder plcValue={"Select"} />}
                      options={item.values.map((i) => ({ label: i, value: i }))}
                      onChange={(e) => onDynamicField({ key: item?._id, value: e })}
                      invalid={!isDynamicFieldValid(item) && validation}
                      value={Data.dynamic_fields?.[item?._id]}
                    />
                    {item.requiredField && (
                      <>
                        <Input type="text" hidden invalid={!isDynamicFieldValid(item) && validation} />
                        <FormFeedback className="ml-1">
                          <FormattedMessage id="This Option is required!" defaultMessage="This Option is required!" />
                        </FormFeedback>
                      </>
                    )}
                  </FormGroup>
                </Fragment>
              );

            case "text":
              return (
                <FormGroup key={index}>
                  <Label for={item.name}>
                    <FormattedMessage id={item.name} defaultMessage={item.name} />
                    {item.requiredField && <strong className="text-danger">*</strong>}
                  </Label>

                  <CustomInput
                    name={item?._id}
                    placeholder={item.name}
                    invalid={!isDynamicFieldValid(item) && validation}
                    handleChange={onDynamicField}
                    value={Data?.dynamic_fields?.[item?._id]}
                  />
                  {item.requiredField && (
                    <FormFeedback className="ml-1" invalid={!isDynamicFieldValid(item) && validation}>
                      This field is required!
                    </FormFeedback>
                  )}
                </FormGroup>
              );
            default:
              return <div />;
          }
        })}
        <FormGroup>
          <Label>
            <FormattedMessage id="Description" defaultMessage="Description" />
            <strong className="text-danger">*</strong>
          </Label>

          <Editor setValue={setValue} value={value} />
          <Input type="text" hidden invalid={!!(HtmlStringConvert(value).trim()?.length === 0 && validation)} />
          <FormFeedback
            className="ml-1"
            invalid={HtmlStringConvert(value).trim()?.length === 0 && validation ? "true" : "false"}
          >
            This field is required!
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <FileUploader
            fileRef={fileRef}
            handleChange={(e) =>
              setData((d) => ({
                ...d,
                attachment: e,
              }))
            }
            accept=".jpg, .jpeg, .png, .svg, .pdf, .xlsx, .xls"
            size={5000000}
            onlyUploader
            value={Data.attachment}
            uploaderText="Upload"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {ticketDone ? (
          <LoaderButton title="Creating Ticket..." />
        ) : (
          <Button
            color="primary"
            onClick={() => handleSubmit(Data)}
            disabled={ticketDone || value?.trim()?.length === 0}
          >
            <FormattedMessage id="Submit" defaultMessage="Submit" />
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
export default SupportForm;
